<template>
  <div>
    <video controls
           :style="{ 'width': maxWidth + 'px' }">
      <source
          :src="`${api}/paste/${this.code}/raw`"
      >
    </video>
  </div>
</template>

<script>
import { URL_API } from '@/lib/constants'

export default {
  name: "VideoDetail",
  props: {
    code: String,
    maxWidth: Number,
  },
  computed: {
    api() {
      return URL_API
    },
  },
}
</script>

<style scoped>

</style>